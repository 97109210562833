import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { cloneDeep, find, isEmpty, isFinite, maxBy, orderBy, set, update } from 'lodash';
import { Spin } from 'antd';
import { AiOutlineLoading } from 'react-icons/ai';
import VectorSource from 'ol/source/Vector';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { LineString, Point } from 'ol/geom';
import { ModifyEvent } from 'ol/interaction/Modify';
import { boundingExtent } from 'ol/extent';
import { Draw, Modify, Select } from 'ol/interaction';
import { Fill, Stroke, Style, Text as OlText } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { getDistance } from 'ol/sphere';
import CircleStyle from 'ol/style/Circle';
import { click } from 'ol/events/condition';
import { white } from 'constants/theme.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import ResumeRoutGenerationModal from 'components/Modal/ResumeRoutGenerationModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { EditRouteTowerPayloadType, RouteDetail, RouteState, TowerType } from 'store/route/types';
import { getDeviationAngleData } from 'store/route/actions';
import {
  setLineRouteCoords,
  setLineRouteLonLatCoords,
  setLineRouteTowerPayload,
  setLineTowerDetails
} from 'store/route';
import { getAsyncStorageValue } from 'utils/localStorage';
import AddNewPointPopUp from './AddNewPointPopUp';
import { useMap } from './MapContext';
import AddnewGntPoint from './AddnewGntPoint';

interface EditRouteLayerV2PropsType {
  isShowTowerText: boolean;
  editedRoute: RouteDetail | null;
  handleSaveEditRouteCoordsData: Function;
  handleEditRoute: Function;
  handleEditSaveAOIRoutebtn: (isBool: boolean) => void;
  handleConfirmResumeRoute: Function;
}

const EditRouteLayerV2 = ({
  isShowTowerText,
  editedRoute,
  // eslint-disable-next-line
  handleSaveEditRouteCoordsData,
  handleConfirmResumeRoute,
  handleEditRoute,
  handleEditSaveAOIRoutebtn
}: EditRouteLayerV2PropsType) => {
  const map = useMap();

  const dispatch = useAppDispatch();
  const {
    towerTypes,
    lineTowerDetails,
    lineRouteCoords,
    lineRouteTowerPayload,
    lineRouteLonLatCoords,
    isRequestingResumeRoute
  }: RouteState = useAppSelector((state) => state.route);
  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer>();
  const selectInteractionRef = useRef<Select>();
  const modifyInteractionRef = useRef<Modify>();
  const vectorSourceRef = useRef<VectorSource>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isModifyTower, setModifyTower] = useState<boolean>(false);
  const [isDeleteTower, setDeleteTower] = useState<boolean>(false);
  const [deleteTowerFeature, setDeleteTowerFeature] = useState<any>(null);
  const [maxTowerId, setMaxTowerId] = useState<number | null>(null);
  const [drawMode, setDrawMode] = useState<boolean>(false);
  const [isResumeTowerModal, setResumeTowerModal] = useState<boolean>(false);
  const [changeTowerData, setChangeTowerData] = useState<{
    type: string;
    towerName: string;
    deviation_angle: number;
  } | null>(null);
  const [changeTowerCoord, setChangeTowerCoord] = useState<string | null>(null);
  const [count, setCount] = useState<number>(0);
  const [gntTowerData, setGntTowerData] = useState<{
    coordinates: number[];
    type: string;
    name: string;
    deviation_angle: number;
  } | null>(null);

  const handleConfirmEditRoute = () => {
    // handleEditSaveAOIRoutebtn(false);
    setDeleteTower(false);
  };

  const handleDeletePoint = () => {
    if (deleteTowerFeature) {
      if (vectorSourceRef.current) {
        const routeLineCoordsData = [...lineRouteCoords];
        routeLineCoordsData.splice(deleteTowerFeature.get('index'), 1);
        dispatch(setLineRouteCoords(routeLineCoordsData));
        const routeLineCoordsLonLatData = [...lineRouteLonLatCoords];
        routeLineCoordsLonLatData.splice(deleteTowerFeature.get('index'), 1);
        dispatch(setLineRouteLonLatCoords(routeLineCoordsLonLatData));
        const routeLineTowersData = [...lineRouteTowerPayload];
        routeLineTowersData.splice(deleteTowerFeature.get('index'), 1);
        dispatch(setLineRouteTowerPayload(routeLineTowersData));
        const linetowers = [...lineTowerDetails];
        linetowers.splice(deleteTowerFeature.get('index'), 1);
        dispatch(setLineTowerDetails(linetowers));

        vectorSourceRef.current.removeFeature(deleteTowerFeature);

        setDeleteTowerFeature(null);
        setDeleteTower(false);
        setModifyTower(false);
      }
    }
  };

  const handleClosePopUp = () => {
    setModifyTower(false);
    setDrawMode(false);
    setGntTowerData(null);
    setDeleteTowerFeature(null);
    setDeleteTower(false);
    // handleEditSaveAOIRoutebtn(true);
    setChangeTowerCoord(null);
    setChangeTowerData(null);
  };

  useLayoutEffect(() => {
    if (editedRoute) {
      dispatch(setLineTowerDetails([...orderBy(editedRoute.towers, 'id')]));
      const coords = [...orderBy(editedRoute.towers, 'id')].map((t: TowerType) =>
        fromLonLat(t.geometry.coordinates)
      );
      const lonlatCoords = [...orderBy(editedRoute.towers, 'id')].map(
        (t: TowerType) => t.geometry.coordinates
      );

      setMaxTowerId(maxBy([...orderBy(editedRoute.towers, 'id')], 'id')?.id || null);
      const payload: EditRouteTowerPayloadType[] = [...orderBy(editedRoute.towers, 'id')].map(
        (t: TowerType, index: number) => {
          return {
            coordinates: t.geometry.coordinates,
            type_id: find(towerTypes, ['name', t.type])?.id || null,
            index,
            deviation_angle: Number(t.deviation_angle)
          };
        }
      );

      dispatch(setLineRouteCoords([...coords]));
      dispatch(setLineRouteLonLatCoords([...lonlatCoords]));
      dispatch(setLineRouteTowerPayload([...payload]));
    }
  }, [editedRoute]);

  useEffect(() => {
    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      zIndex: 101,
      style: (feature) => {
        const color = feature.get('fillColor') || 'blue';
        if (feature.getGeometry() instanceof Point) {
          return new Style({
            image: new CircleStyle({
              radius: 4,
              fill: new Fill({ color }),
              stroke: new Stroke({ color })
            })
          });
        }
        return new Style({
          stroke: new Stroke({
            color,
            width: 2,
            lineDash: [5, 10]
          })
        });
      }
    });

    if (map) {
      map.on('click', () => {
        selectInteractionRef.current?.getFeatures().clear();
      });
      map.addLayer(vectorLayerRef.current);
    }

    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
        modifyInteractionRef.current = undefined;
        selectInteractionRef.current?.getFeatures().clear();
      }
    };
  }, [map]);

  useEffect(() => {
    if (map && vectorSourceRef.current && editedRoute) {
      // vectorSourceRef.current?.clear();

      const lineStringFeature = new Feature({
        geometry: new LineString([...lineRouteCoords]),
        fillColor: editedRoute?.color!,
        zIndex: 100
      });

      lineStringFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color: editedRoute?.color,
            width: 1
          })
        })
      );
      lineStringFeature.set('id', 'mainlinestring');
      vectorSourceRef.current?.addFeature(lineStringFeature);

      map.render();
    }
    return () => {
      vectorSourceRef.current?.clear();
    };
  }, [map, count, lineRouteCoords, lineTowerDetails, isShowTowerText]);

  useEffect(() => {
    if (map && vectorSourceRef.current && editedRoute) {
      // eslint-disable-next-line
      [...lineRouteLonLatCoords].forEach((coord, i) => {
        const fromlonglatcoord = fromLonLat(coord);

        const labelFeature = new Feature({
          geometry: new Point(fromlonglatcoord),
          zIndex: 100
        });

        const tower = [...lineTowerDetails].find((tower) => {
          return (
            Number(tower.geometry.coordinates[0].toFixed(11)) === Number(coord[0].toFixed(11)) &&
            Number(tower.geometry.coordinates[1].toFixed(11)) === Number(coord[1].toFixed(11))
          );
        });

        labelFeature.setId(`tower-point-${tower?.id}-${i}`);
        labelFeature.set('towertype', tower?.type);
        labelFeature.set('deviationAngle', tower?.deviation_angle);
        labelFeature.set('name', tower?.name);
        labelFeature.set('index', i);
        const geometry = labelFeature.getGeometry();
        if (geometry instanceof Point) {
          let extent = geometry.getExtent();
          if (!extent || extent.some((value) => !isFinite(value))) {
            const coordinates = geometry.getCoordinates();
            const buffer = 1e-5;
            extent = boundingExtent([
              [coordinates[0] - buffer, coordinates[1] - buffer],
              [coordinates[0] + buffer, coordinates[1] + buffer]
            ]);
          }
          labelFeature.set('fixedExtent', extent);
        }
        const zoom = map?.getView().getZoom() || 0;
        const fontSize = Math.max(8, 30 - zoom * 2);
        labelFeature.setStyle(
          new Style({
            text: isShowTowerText
              ? new OlText({
                  text: tower?.name || '',
                  font: `${fontSize}px`,
                  fill: new Fill({ color: editedRoute?.color }),
                  offsetY: -10,
                  offsetX: i % 2 === 0 ? 50 : -10
                })
              : undefined,
            image: new CircleStyle({
              radius: 4,
              fill: new Fill({ color: editedRoute.color }),
              stroke: new Stroke({ color: editedRoute.color })
            }),
            zIndex: 101
          })
        );

        vectorSourceRef.current?.addFeature(labelFeature);
      });

      map.render();
    }
    return () => {
      vectorSourceRef.current?.clear();
    };
  }, [map, lineRouteCoords, lineTowerDetails, editedRoute, isShowTowerText]);

  // eslint-disable-next-line
  useEffect(() => {
    setLoading(true);
    if (map && !isEmpty(lineRouteCoords) && editedRoute) {
      const modify = new Modify({
        source: vectorSourceRef.current,
        condition: (e: any) => {
          const feature = e.features?.getArray()[0];
          const geometry = feature?.getGeometry();

          if (geometry?.getType() === 'LineString') {
            const coords = geometry?.getCoordinates();
            const modifiedCoord = e?.coordinate;
            const firstPoint = coords[0];
            const lastPoint = coords[coords.length - 1];

            if (
              (modifiedCoord[0] === firstPoint[0] && modifiedCoord[1] === firstPoint[1]) ||
              (modifiedCoord[0] === lastPoint[0] && modifiedCoord[1] === lastPoint[1])
            ) {
              return false;
            }
          }
          return true;
        }
      });
      modifyInteractionRef.current = modify;
      map.addInteraction(modify);

      modify.on('modifyend', (event: ModifyEvent) => {
        setCount(count + 1);
        handleEditSaveAOIRoutebtn(false);
        setDeleteTower(false);
        const modifiedFeatures = event.features.getArray();

        const modifyLinestringFeatures = // @ts-ignore
          modifiedFeatures.filter((feature) => feature.getGeometry().getType() === 'LineString');

        if (modifyLinestringFeatures?.length > 0) {
          const newVertexLineCoordinates = modifyLinestringFeatures[0]
            ?.getGeometry()
            // @ts-ignore
            ?.getCoordinates();

          if (newVertexLineCoordinates.length > lineRouteCoords?.length) {
            const differentData: any = [];

            // Compare each element in updatedData with data
            newVertexLineCoordinates.forEach((coord: any) => {
              const found = cloneDeep([...lineRouteCoords]).some(
                (d) => d[0] === coord[0] && d[1] === coord[1]
              );
              if (!found) {
                differentData.push(coord);
              }
            });

            const pointIndex = newVertexLineCoordinates.findIndex(
              (data: any) => data[0] === differentData[0][0] && data[1] === differentData[0][1]
            );

            const newPoint = newVertexLineCoordinates[pointIndex];
            const prevPoint = newVertexLineCoordinates[pointIndex - 1];
            const nextPoint = newVertexLineCoordinates[pointIndex + 1];

            getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
              dispatch(
                getDeviationAngleData(
                  toLonLat(prevPoint).toString(),
                  toLonLat(newPoint).toString(),
                  toLonLat(nextPoint).toString(),
                  token,
                  (data: { type: string; deviation_angle: number }) => {
                    selectInteractionRef.current?.getFeatures().clear();
                    const oldData = cloneDeep([...lineRouteTowerPayload]);

                    const updateTowerDatas = cloneDeep([...lineTowerDetails]);
                    const updatedTowerData = {
                      id: maxTowerId! + 1,
                      route_id: updateTowerDatas[0].route_id,
                      type: data.type,
                      deviation_angle: String(data.deviation_angle),
                      name: `AP-${updateTowerDatas.length + 1}`,
                      geometry: {
                        type: 'Point',
                        coordinates: toLonLat(newPoint)
                      }
                    };

                    updateTowerDatas.splice(pointIndex, 0, updatedTowerData);
                    const towerPayloadData = {
                      coordinates: toLonLat(newPoint),
                      type_id: find(towerTypes, ['name', data.type])?.id || null,
                      index: pointIndex,
                      deviation_angle: Number(data.deviation_angle)
                    };
                    oldData.splice(pointIndex, 0, towerPayloadData);

                    dispatch(setLineRouteCoords([...newVertexLineCoordinates]));
                    dispatch(
                      setLineRouteLonLatCoords([
                        ...newVertexLineCoordinates.map((coord: number[]) => toLonLat(coord))
                      ])
                    );
                    dispatch(setLineTowerDetails([...updateTowerDatas]));
                    dispatch(setLineRouteTowerPayload([...oldData]));
                    handleEditRoute();
                    setChangeTowerCoord(
                      [
                        toLonLat(newPoint)[1].toFixed(4),
                        toLonLat(newPoint)[0].toFixed(4)
                      ].toString()
                    );
                    setChangeTowerData({
                      towerName: `AP-${updateTowerDatas.length + 1}`,
                      type: data.type,
                      deviation_angle: Number(data.deviation_angle)
                    });
                    setModifyTower(true);
                  }
                )
              );
            });
          }
        }

        const modifyPointFeatures = // @ts-ignore
          modifiedFeatures.filter((feature) => feature.getGeometry().getType() === 'Point');

        // eslint-disable-next-line
        for (const feature of modifyPointFeatures) {
          if (feature.get('index')) {
            const lineCoords = [...lineRouteCoords];
            const pointIndex: number = feature.get('index');
            const geometry = feature.getGeometry();
            if (geometry instanceof Point) {
              // @ts-ignore
              const pointcoords = geometry.getCoordinates();

              // @ts-ignore
              const currentpoint = toLonLat(pointcoords);
              const prevPoint = lineCoords[pointIndex - 1] || null;
              const nextPoint = lineCoords[pointIndex + 1] || null;

              getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
                dispatch(
                  getDeviationAngleData(
                    toLonLat(prevPoint).toString(),
                    currentpoint.toString(),
                    toLonLat(nextPoint).toString(),
                    token,
                    (data: { type: string; deviation_angle: number }) => {
                      selectInteractionRef.current?.getFeatures().clear();
                      feature.set('towertype', data.type);
                      feature.set('deviationAngle', data.deviation_angle);
                      feature.set('name', `AP-${pointIndex}`);
                      const oldData = [...lineRouteTowerPayload];
                      update(oldData, pointIndex, (item: EditRouteTowerPayloadType) => ({
                        ...item,
                        type_id: find(towerTypes, ['name', data.type])?.id || null,
                        coordinates: currentpoint
                      }));
                      set(lineCoords, pointIndex, fromLonLat(currentpoint));

                      const updateTowerData = cloneDeep([...lineTowerDetails]);
                      updateTowerData[pointIndex].geometry.coordinates = currentpoint;
                      updateTowerData[pointIndex].type = data.type;
                      updateTowerData[pointIndex].deviation_angle = data.deviation_angle;

                      dispatch(setLineTowerDetails([...updateTowerData]));
                      dispatch(setLineRouteCoords([...lineCoords]));
                      dispatch(
                        setLineRouteLonLatCoords(
                          [...lineCoords].map((coord: number[]) => toLonLat(coord))
                        )
                      );
                      dispatch(setLineRouteTowerPayload(oldData));
                      handleEditRoute();
                      setChangeTowerCoord(
                        [currentpoint[1].toFixed(4), currentpoint[0].toFixed(4)].toString()
                      );
                      setChangeTowerData({
                        towerName: `AP-${pointIndex}`,
                        type: data.type,
                        deviation_angle: Number(data.deviation_angle)
                      });
                      setModifyTower(true);
                    }
                  )
                );
              });
            }
          }
        }
      });

      const select = new Select({
        condition: click,
        // filter: (feature) => feature.get('type') === 'Point'
        layers: [vectorLayerRef.current]
      });

      map.addInteraction(select);
      selectInteractionRef.current = select;

      select.on('select', (event) => {
        const selectedFeature = event.selected[0];

        setDeleteTower(true);
        if (selectedFeature && selectedFeature.getGeometry() instanceof Point) {
          // @ts-ignore
          const coordinates = selectedFeature.getGeometry().getCoordinates();
          const towerType = selectedFeature.get('towertype');
          const towerName = selectedFeature.get('name');
          const deviationAngle = selectedFeature.get('deviationAngle');
          const coord = toLonLat(coordinates);

          setDeleteTowerFeature(selectedFeature);
          setModifyTower(true);
          setChangeTowerCoord([coord[1].toFixed(4), coord[0].toFixed(4)].toString());
          setChangeTowerData({
            towerName,
            type: towerType,
            deviation_angle: Number(deviationAngle)
          });

          if (towerType === 'Gantry') {
            setGntTowerData({
              coordinates: coord,
              type: towerType,
              name: towerName,
              deviation_angle: Number(deviationAngle)
            });
            setModifyTower(false);
            setChangeTowerData(null);
          } else {
            setGntTowerData(null);
          }
        }
      });

      setTimeout(() => {
        setLoading(false);
      }, 1500);

      return () => {
        map.removeInteraction(select);
        map.removeInteraction(modify);
        modifyInteractionRef.current = undefined;
        vectorSourceRef.current?.clear();
      };
    }
  }, [map, count, lineRouteCoords, lineTowerDetails, isShowTowerText]);

  // show length between two tower point
  useEffect(() => {
    if (!isEmpty(lineRouteLonLatCoords)) {
      const lineCoords = [...lineRouteLonLatCoords];
      // eslint-disable-next-line
      lineCoords.forEach((coord, i) => {
        const start = lineCoords[i];
        const end = lineCoords[i + 1];
        if (!(!start || !end || start.length < 2 || end.length < 2 || start[0] === end[0])) {
          const segmentDistance = getDistance(start, end).toFixed(2);
          const midpoint = fromLonLat([(start[0] + end[0]) / 2, (start[1] + end[1]) / 2]);
          const distanceFeature = new Feature({
            geometry: new Point(midpoint)
          });
          distanceFeature.setStyle(
            new Style({
              text: new OlText({
                text: `${segmentDistance} m`,
                fill: new Fill({ color: editedRoute?.color }),
                placement: 'line',
                rotateWithView: true,
                rotation: 0,
                overflow: true,
                offsetY: -20
              })
            })
          );

          vectorSourceRef.current?.addFeature(distanceFeature);
        }
      });
    }
  }, [lineRouteLonLatCoords]);

  useEffect(() => {
    let draw: any;

    if (map && drawMode && gntTowerData && !isEmpty(lineRouteCoords)) {
      const fullLineCoords = [...lineRouteCoords];
      const fullLineLonLatCoords = [...lineRouteLonLatCoords];

      const lineCoords = [fromLonLat(gntTowerData.coordinates)];
      const lineFeature = new Feature({
        geometry: new LineString(lineCoords),
        style: () => {
          return new Style({
            zIndex: 101,
            stroke: new Stroke({
              color: editedRoute?.color,
              width: 1,
              lineDash: [5, 10]
            })
          });
        }
      });
      vectorSourceRef.current?.addFeature(lineFeature);
      draw = new Draw({
        source: vectorSourceRef.current,
        type: 'LineString',
        style: new Style({
          zIndex: 101,
          stroke: new Stroke({
            color: editedRoute?.color,
            width: 1,
            lineDash: [5, 10]
          })
        }),
        geometryFunction: (coordinates, geometry) => {
          if (!geometry) {
            geometry = new LineString([...lineCoords]);
          }
          geometry.setCoordinates([...lineCoords, ...coordinates.slice(1)]);
          return geometry;
        }
      });
      map.addInteraction(draw);

      draw.on('drawend', (event: any) => {
        const newCoordinates = event.feature.getGeometry().getCoordinates();
        fullLineCoords.push(newCoordinates[1]);
        fullLineLonLatCoords.push(toLonLat(newCoordinates[1]));
        const gntPrevPointCoords = toLonLat(fullLineCoords[fullLineCoords.length - 2]);

        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(
            getDeviationAngleData(
              gntPrevPointCoords.toString(),
              toLonLat(newCoordinates[0]).toString(),
              toLonLat(newCoordinates[1]).toString(),
              token,
              (data: { type: string; deviation_angle: number }) => {
                selectInteractionRef.current?.getFeatures().clear();
                const oldTowerPayloadData = cloneDeep([...lineRouteTowerPayload]);

                oldTowerPayloadData[oldTowerPayloadData.length - 1].type_id =
                  find(towerTypes, ['name', data.type])?.id || null;

                const newlytowerpayloaddata = {
                  coordinates: toLonLat(newCoordinates[1]),
                  type_id: null,
                  index: oldTowerPayloadData[oldTowerPayloadData.length - 1].index + 1,
                  deviation_angle: Number(data.deviation_angle)
                };
                oldTowerPayloadData.push(newlytowerpayloaddata);

                const updateTowerDatas = cloneDeep([...lineTowerDetails]);

                updateTowerDatas[updateTowerDatas.length - 1].name = `AP-${
                  updateTowerDatas.length + 1
                }`;
                updateTowerDatas[updateTowerDatas.length - 1].type = data.type;
                updateTowerDatas[updateTowerDatas.length - 1].deviation_angle = String(
                  data.deviation_angle
                );
                const newlyGNTTowerData = {
                  id: maxTowerId! + 1,
                  route_id: updateTowerDatas[0].route_id,
                  type: 'Gantry',
                  deviation_angle: '0.0',
                  name: 'GNT',
                  geometry: {
                    type: 'Point',
                    coordinates: toLonLat(newCoordinates[1])
                  }
                };
                updateTowerDatas.push(newlyGNTTowerData);

                // @ts-ignore
                lineFeature.getGeometry().setCoordinates(fullLineCoords);

                dispatch(setLineRouteCoords(fullLineCoords));
                dispatch(setLineRouteLonLatCoords(fullLineLonLatCoords));
                dispatch(setLineTowerDetails([...updateTowerDatas]));
                dispatch(setLineRouteTowerPayload([...oldTowerPayloadData]));
                handleEditRoute();
                setDrawMode(false);
                setGntTowerData(null);
              }
            )
          );
        });
      });
    }

    return () => {
      if (draw) {
        map?.removeInteraction(draw);
      }
      modifyInteractionRef.current = undefined;
    };
  }, [drawMode, editedRoute, lineRouteCoords]);

  const handleDrawmode = () => {
    setDrawMode(true);
  };

  const handleDrawmodeEnd = () => {
    setDrawMode(false);
    modifyInteractionRef.current = undefined;
  };

  const handleShowResumeRoute = () => {
    setResumeTowerModal(true);
  };

  const handleOkayResumeRoute = () => {
    handleConfirmResumeRoute();
  };

  const handleCancelResumeRoute = () => {
    setResumeTowerModal(false);
  };

  return (
    <>
      <Spin
        className="spinner"
        spinning={loading}
        style={{
          width: '100%',
          position: 'absolute'
        }}
        indicator={<AiOutlineLoading className="spin-loader" speed={500} color={white} />}
      />
      {isModifyTower && changeTowerData && changeTowerData.type !== 'Gantry' && (
        <AddNewPointPopUp
          isDeleteTower={false}
          towerCoord={changeTowerCoord}
          towerData={changeTowerData}
          handleClosePopUp={handleClosePopUp}
          handleConfirmEditRoute={handleConfirmEditRoute}
          handleDeletePoint={() => {}}
        />
      )}
      {gntTowerData && gntTowerData.type === 'Gantry' && (
        <AddnewGntPoint
          towers={editedRoute ? [...orderBy(editedRoute?.towers, 'id')] : []}
          newTowers={lineTowerDetails}
          gntTowerData={gntTowerData}
          handleClosePopUp={handleClosePopUp}
          handleDrawmode={handleDrawmode}
          handleDrawmodeEnd={handleDrawmodeEnd}
          handleShowResumeRoute={handleShowResumeRoute}
        />
      )}
      {isDeleteTower && changeTowerData && changeTowerData.type !== 'Gantry' && (
        <AddNewPointPopUp
          isDeleteTower={isDeleteTower}
          towerCoord={changeTowerCoord}
          towerData={changeTowerData}
          handleClosePopUp={handleClosePopUp}
          handleConfirmEditRoute={handleConfirmEditRoute}
          handleDeletePoint={handleDeletePoint}
        />
      )}
      {isResumeTowerModal && (
        <ResumeRoutGenerationModal
          isOpen={isResumeTowerModal}
          isLoading={isRequestingResumeRoute}
          handleOkayResumeRoute={handleOkayResumeRoute}
          handleCancelResumeRoute={handleCancelResumeRoute}
        />
      )}
    </>
  );
};

export default EditRouteLayerV2;
