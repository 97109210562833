import { useState } from 'react';
import moment from 'moment';
import { useNavigate, NavLink } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/useReduxHook';
import { Layout, PageHeader, Tooltip, Typography } from 'antd';
import { FaRegFolderOpen } from 'react-icons/fa';
import { RiBarChartBoxLine } from 'react-icons/ri';
import { AiOutlinePieChart } from 'react-icons/ai';
import { DateFormat6 } from 'constants/common.constant';
import WorkspaceWhiteIcon from 'assets/svg/WorkspaceWhiteIcon.svg';
import WorkspaceBlackIcon from 'assets/svg/WorkspaceBlackIcon.svg';
import backIcon from 'assets/svg/backIcon.svg';
import { greyShed, white } from 'constants/theme.constants';
import { routes } from 'constants/pageRoutes.constants';
import { clearProjectData } from 'store/users/actions';
import './styles.less';

const { Sider } = Layout;
const { Text } = Typography;

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { project } = useAppSelector((state) => state.projects);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleBackNavigation = () => {
    navigate(routes.ProjectList, { replace: true });
    dispatch(clearProjectData());
  };

  return (
    <Sider
      className="mainSideBar"
      data-testid="sidebar"
      collapsible
      width={250}
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 8px' }}>
      <div>
        <PageHeader
          className="back-button"
          title={
            !collapsed ? (
              <div className="back-title-main" onClick={handleBackNavigation}>
                <img className="back-image" src={backIcon} alt="backIcon" />
                <Text className="back-text">back</Text>
              </div>
            ) : (
              <div className="back-title-main" onClick={handleBackNavigation}>
                <img className="back-image" src={backIcon} alt="backIcon" />
              </div>
            )
          }
          backIcon={null}
        />

        <div className="main-divider">
          <div className="inner-divider" />
        </div>

        <ul>
          <div>
            <NavLink
              to={routes.ProjectTimelineUrl}
              className={({ isActive }) => (isActive ? 'activeLink' : undefined)}>
              {collapsed ? (
                <Tooltip
                  title="Project Timeline"
                  placement="right"
                  overlayClassName="menu-tooltip-card"
                  color={white}
                  overlayInnerStyle={{ color: `${greyShed}` }}>
                  <RiBarChartBoxLine size={20} />
                </Tooltip>
              ) : (
                <RiBarChartBoxLine size={20} />
              )}
              {!collapsed && <Text className="link-title">Project Timeline</Text>}
            </NavLink>
            <NavLink
              to={routes.FileRepository}
              className={({ isActive }) => (isActive ? 'activeLink' : undefined)}>
              {collapsed ? (
                <Tooltip
                  title="File Repository"
                  placement="right"
                  overlayClassName="menu-tooltip-card"
                  color={white}
                  overlayInnerStyle={{ color: `${greyShed}` }}>
                  <FaRegFolderOpen size={20} />
                </Tooltip>
              ) : (
                <FaRegFolderOpen size={20} />
              )}

              {!collapsed && <Text className="link-title">File Repository</Text>}
            </NavLink>
            <NavLink
              to={routes.Workspace}
              onClick={() => setCollapsed(true)}
              className={({ isActive }) => (isActive ? 'activeLink ' : undefined)}>
              {({ isActive }: any) =>
                collapsed ? (
                  <div style={{ width: 'unset', position: 'relative' }}>
                    <Tooltip
                      title="Workspace"
                      placement="right"
                      overlayClassName="menu-tooltip-card"
                      color={white}
                      overlayInnerStyle={{ color: `${greyShed}` }}>
                      <img
                        className="aoi-image"
                        src={isActive ? WorkspaceWhiteIcon : WorkspaceBlackIcon}
                        alt="Workspace"
                        style={{ position: 'relative' }}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <>
                    <img
                      className="aoi-image"
                      src={isActive ? WorkspaceWhiteIcon : WorkspaceBlackIcon}
                      alt="AOI"
                    />
                    <div>
                      <Text className="link-title" style={{ position: 'relative' }}>
                        Workspace
                      </Text>
                    </div>
                  </>
                )
              }
            </NavLink>
            <NavLink
              to={routes.Compare}
              className={({ isActive }) => (isActive ? 'activeLink' : undefined)}>
              {collapsed ? (
                <Tooltip
                  title="Compare"
                  placement="right"
                  overlayClassName="menu-tooltip-card"
                  color={white}
                  overlayInnerStyle={{ color: `${greyShed}` }}>
                  <AiOutlinePieChart size={20} />
                </Tooltip>
              ) : (
                <AiOutlinePieChart size={20} />
              )}
              {!collapsed && <Text className="link-title">Compare</Text>}
            </NavLink>
          </div>
        </ul>
      </div>
      {!collapsed &&
        project?.line_voltage &&
        project?.circuit_type &&
        project?.bid_submission_date && (
          <ul className="project_circuit_sections">
            <li>
              <Text className="title">Line Voltage</Text>
              <Text className="title font_size">{project?.line_voltage} kV</Text>
            </li>
            <li>
              <Text className="title">Circuit Type</Text>
              <Text className="title font_size">{project?.circuit_type}</Text>
            </li>
            <li>
              <Text className="title">Bid Submission Date</Text>
              <Text className="title font_size">
                {moment(project?.bid_submission_date).format(DateFormat6)}
              </Text>
            </li>
          </ul>
        )}
    </Sider>
  );
};

export default SideBar;
