import { isEmpty, upperFirst } from 'lodash';
import tokml from '@maphubs/tokml';
import moment from 'moment';
import { green, lightGrey, orange, primary, red } from 'constants/theme.constants';
import { ProjectStatus } from 'store/projects/types';
import { AOIDetail, GeometryType } from 'store/aoi/types';
import { RouteDetail, TowerType } from 'store/route/types';
import { Polygon } from 'ol/geom';
import { getArea } from 'ol/sphere';
import { fromLonLat } from 'ol/proj';

export const shortLabel = (string: string) => {
  if (!isEmpty(string)) {
    const label = string
      .split(' ')
      .map((word) => upperFirst(word[0]))
      .join('');

    return label;
  }
  return '';
};

export const analyticsFormKey = (string: string) => {
  return string
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .split(' ')
    .join('_')
    .toLowerCase();
};

export const fromateFeatureKey = (string: string) => {
  return string.replace('  ', ' ').replace('( ', '(').toLowerCase().trim();
};

export const statusClass = (percent: number, status: ProjectStatus) => {
  let classname = 'in-progress';

  if (percent === 0) {
    classname = 'not-started';
  } else if (percent > 0 && percent < 100) {
    classname = 'in-progress';
  } else if (status === ProjectStatus.Closed && percent === 100) {
    classname = 'closed';
  } else if (percent === 100) {
    classname = 'completed';
  }

  return classname;
};

export const strokeColor = (status: ProjectStatus) => {
  let color = lightGrey;

  switch (status) {
    case ProjectStatus.NotStarted:
      color = lightGrey;
      break;
    case ProjectStatus.InProgress:
      color = orange;
      break;
    case ProjectStatus.Completed:
      color = green;
      break;
    case ProjectStatus.Closed:
      color = primary;
      break;
    default:
      color = orange;
      break;
  }

  return color;
};

export const strokePercentageColor = (percent: number, status: ProjectStatus) => {
  let color = lightGrey;

  if (percent === 0) {
    color = red;
  } else if (percent > 0 && percent < 100) {
    color = orange;
  } else if (status === ProjectStatus.Closed && percent === 100) {
    color = primary;
  } else if (percent === 100) {
    color = primary;
  }

  return color;
};

export const readFileAsyncAsText = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsText(file);
  });
};

export const getNumber = (length: any) => {
  if (!length) return 0;
  const num = length.toString().split(' ');
  return Number(num[0]);
};

export const getCost = (cost: any, length: any) => {
  if (!cost || !length) return 0;

  const costRoute = Number(cost) * Number(getNumber(length));
  return Number(costRoute);
};

export const numberWithCommas = (x: number) => {
  const cost = x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  return Number(cost);
};

export const getDateTimeFormat = (dateTimeFormat: string) => {
  const date = moment(dateTimeFormat);

  let formattedTime;
  const diffMinutes = moment().diff(date, 'minute');
  const diffHours = moment().diff(date, 'hour');

  if (diffMinutes < 60) {
    formattedTime = `${diffMinutes} min ago`;
  } else if (diffHours < 24) {
    formattedTime = `${diffHours} hour ago`;
  } else {
    formattedTime = date.format('D MMM');
  }
  return formattedTime;
};

export const geoJsonTOKmlDownloadFile = (
  aoiData: AOIDetail | RouteDetail,
  index: number,
  text: string
) => {
  const geojson: any = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: aoiData.geometry,
        properties: {
          title: aoiData.id
        }
      }
    ]
  };
  const kmlDocumentName = tokml(geojson, {
    documentName: `${text}-${index}-${aoiData.id}`
  });
  const blob = new Blob([kmlDocumentName], { type: 'application/vnd.google-earth.kml+xml' });
  const href = URL.createObjectURL(blob);

  // create "a" HTLM element with href to file
  const link = document.createElement('a');
  link.href = href;
  link.download = `${text}-${index}-${aoiData.id}.kml`;
  document.body.appendChild(link);
  link.click();
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const geoJsonTOKmlTowerDownloadFile = (
  routeData: RouteDetail,
  index: number,
  text: string
) => {
  const features = routeData.towers.map((tower: TowerType) => {
    return {
      type: 'Feature',
      geometry: tower.geometry,
      properties: {
        id: tower.id,
        name: tower.name,
        type: tower.type,
        deviation_angle: tower.deviation_angle
      }
    };
  });

  const geojson: any = {
    type: 'FeatureCollection',
    features
  };
  const kmlDocumentName = tokml(geojson, {
    documentName: `${text}-${index}-${routeData.id}`
  });
  const blob = new Blob([kmlDocumentName], { type: 'application/vnd.google-earth.kml+xml' });
  const href = URL.createObjectURL(blob);

  // create "a" HTLM element with href to file
  const link = document.createElement('a');
  link.href = href;
  link.download = `${text}-${index}-${routeData.id}.kml`;
  document.body.appendChild(link);
  link.click();
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const checkSubsetArray = (parentArray: number[], subsetArray: number[]) => {
  return subsetArray.every((el) => {
    return parentArray.includes(el);
  });
};

export const findPointDetails = (point: any, points: any[]) => {
  const index = points.findIndex((p) => p[0] === point[0] && p[1] === point[1]);
  if (index === -1) return null;

  const prevPoint = points[index - 1] || null;
  const nextPoint = points[index + 1] || null;

  return { point, prevPoint, nextPoint };
};

export const getAOIArea = (geometry: GeometryType): string | number => {
  if (geometry && geometry.coordinates && Array.isArray(geometry.coordinates[0])) {
    try {
      const polygon = new Polygon([
        geometry?.coordinates[0]?.map((point: [number, number]) => fromLonLat(point))
      ]);
      return (getArea(polygon) / 1e6).toFixed(2);
    } catch (error) {
      return 'NA';
    }
  } else {
    return 'NA';
  }
};

export const arraysEqual = (a: any[], b: any[]) => {
  return a.length === b.length && a.every((val, index) => val === b[index]);
};

export const findDifferentIndices = (original: number[][], updated: number[][]) => {
  const differentIndices = [];
  const length = Math.min(original.length, updated.length);

  for (let i = 0; i < length; i + 1) {
    if (original[i][0] !== updated[i][0] || original[i][1] !== updated[i][1]) {
      differentIndices.push(i);
    }
  }

  return differentIndices;
};
