import { useEffect, useRef } from 'react';
import { find, isEmpty } from 'lodash';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Polygon } from 'ol/geom';
import Feature from 'ol/Feature';
import { Style, Fill, Stroke } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { aoiBackColors } from 'constants/common.constant';
import { AOIDetail, AOIStausType } from 'store/aoi/types';
import { CoordinateType } from 'types/common.types';
import { useMap } from './MapContext';

interface ApprovedAOILayerProps {
  aoiLists: AOIDetail[] | [];
}

const ApprovedAOILayer = ({ aoiLists }: ApprovedAOILayerProps) => {
  const map = useMap();
  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer>();
  const vectorSourceRef = useRef<VectorSource>();

  const approvedAOI = find(aoiLists, ['status', AOIStausType.wmapproved]);

  useEffect(() => {
    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      style: (feature) => {
        const fillColor = feature.get('fillColor');
        return new Style({
          fill: new Fill({
            color: aoiBackColors[fillColor]
          }),
          stroke: new Stroke({
            color: fillColor,
            width: 0
          }),
          zIndex: 1
        });
      }
    });
    vectorLayerRef.current.set('name', 'aoilist');
    if (map) {
      map.addLayer(vectorLayerRef.current);
    }
    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!vectorSourceRef.current) return;

    vectorSourceRef.current.clear();

    if (
      !isEmpty(approvedAOI) &&
      approvedAOI.geometry &&
      approvedAOI.geometry.type === CoordinateType.Polygon
    ) {
      const coords = approvedAOI.geometry.coordinates[0].map((point: any) => fromLonLat(point));
      const feature = new Feature({
        geometry: new Polygon([coords]),
        fillColor: approvedAOI.color
      });
      feature.setId(approvedAOI.id);
      feature.setStyle(
        new Style({
          fill: new Fill({
            color: aoiBackColors[approvedAOI.color]
          }),
          stroke: new Stroke({
            color: approvedAOI.color,
            width: 2
          }),
          zIndex: approvedAOI.index + 55
        })
      );

      vectorSourceRef.current?.addFeature(feature);
    }
  }, [approvedAOI]);

  return null;
};

export default ApprovedAOILayer;
