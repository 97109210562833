import { toast } from 'react-toastify';
import { orderBy } from 'lodash';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { getAsyncStorageValue } from 'utils/localStorage';
import {
  EDIT_ROUTE_URL,
  GENERATE_ROUTE_URL,
  GET_ROUTE_URL,
  ROUTE_DETAILS_URL,
  GET_ROUTE_TOWER_TYPE_OPTION_URL,
  GET_TOWER_DEVIATION_ANGLE_URL,
  UPDATE_ROUTE_TOWER_TYPE_URL,
  DOWNLOAD_ROUTE_DETAILS_URL,
  RESTART_ROUTE_URL
} from 'utils/apiUrls';
import { setRequestingRefreshToken } from 'store/users';
import { PostResponse, Response } from 'types/common.types';
import { apiCall, downloadReadableStreamFile } from 'utils/connect';
import { AppDispatch } from '..';
import { GenerateRoutePayload, TowerOptionType, UpdateTowerType } from './types';
import {
  setRequestingDeviationAngle,
  setRequestingEditRoute,
  setRequestingGenerateRoute,
  setRequestingRouteFiles,
  setRoutes,
  setRequestingUpdateRouteDetails,
  setRequestingTowerType,
  setRequestingUpdateTowerType,
  setTowerTypes,
  setRequestingDownloadDetails,
  setRequestingResumeRoute
} from '.';

// get Route files data
export const getRouteLists =
  (projectId: number, token: string, callback?: Function) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingRouteFiles(true));

      // eslint-disable-next-line
      const onSuccess = (response: Response<any>) => {
        dispatch(setRoutes(response.data));
        dispatch(setRequestingRouteFiles(false));
        if (callback) callback(response.data);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getRouteLists(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingRouteFiles(false));
        }
      };

      apiCall('GET', GET_ROUTE_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingRouteFiles(false));
      toast.error(error.message);
    }
  };

// generate Route api
export const generateRoute =
  (payload: { vectors: GenerateRoutePayload[] }, projectId: number, token: string) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingGenerateRoute(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(getRouteLists(projectId, token));
        dispatch(setRequestingGenerateRoute(false));
        toast.success(response.message);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(generateRoute(payload, projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingGenerateRoute(false));
        }
      };

      apiCall('POST', GENERATE_ROUTE_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingGenerateRoute(false));
      toast.error(error.message);
    }
  };

// edit Route api
export const editRoute =
  (
    payload: { towers: { coordinates: number[]; type_id: number | null }[] },
    projectId: number,
    routeId: string,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingEditRoute(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(getRouteLists(projectId, token));
        dispatch(setRequestingEditRoute(false));
        toast.success(response.message);
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(editRoute(payload, projectId, routeId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingEditRoute(false));
        }
      };

      apiCall('PUT', EDIT_ROUTE_URL(routeId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingEditRoute(false));
      toast.error(error.message);
    }
  };

// route detail

export const updatesRouteDetails =
  (payload: any, routeId: string, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingUpdateRouteDetails(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(setRequestingUpdateRouteDetails(false));
        toast.success(response.message);
      };

      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(updatesRouteDetails(payload, routeId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingUpdateRouteDetails(false));
        }
      };

      apiCall('PUT', ROUTE_DETAILS_URL(routeId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingUpdateRouteDetails(false));
      toast.error(error.message);
    }
  };

// get Tower type data
export const getTowerTypeOption =
  (token: string, callback?: Function) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingTowerType(true));

      const onSuccess = (response: Response<TowerOptionType[] | []>) => {
        dispatch(setRequestingTowerType(false));
        dispatch(setTowerTypes(orderBy(response.data, 'name')));
        if (callback) callback(response);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getTowerTypeOption(reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingTowerType(false));
        }
      };

      apiCall('GET', GET_ROUTE_TOWER_TYPE_OPTION_URL, '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingTowerType(false));
      toast.error(error.message);
    }
  };

// get Deviation angle data
export const getDeviationAngleData =
  (prevpoint: string, editpoint: string, nextpoint: string, token: string, callback?: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingDeviationAngle(true));

      const onSuccess = (response: Response<any>) => {
        dispatch(setRequestingDeviationAngle(false));
        if (callback) callback(response.data);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getDeviationAngleData(prevpoint, editpoint, nextpoint, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDeviationAngle(false));
        }
      };

      apiCall(
        'GET',
        GET_TOWER_DEVIATION_ANGLE_URL(prevpoint, editpoint, nextpoint),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingDeviationAngle(false));
      toast.error(error.message);
    }
  };

// edit Route Tower type
export const editRouteTowerType =
  (payload: UpdateTowerType, towerId: number, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingUpdateTowerType(true));

      const onSuccess = () => {
        dispatch(setRequestingUpdateTowerType(false));
        callback(true);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(editRouteTowerType(payload, towerId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingUpdateTowerType(false));
          callback(false);
        }
      };

      apiCall('PATCH', UPDATE_ROUTE_TOWER_TYPE_URL(towerId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingUpdateTowerType(false));
      toast.error(error.message);
      callback(false);
    }
  };

export const downloadRouteDetails =
  (routeId: string, token: string) => async (dispatch: AppDispatch) => {
    dispatch(setRequestingDownloadDetails(true));

    const onSuccess = (blob: Blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `Route${routeId}.xlsx`);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(blobUrl);
      dispatch(setRequestingDownloadDetails(false));
    };

    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(downloadRouteDetails(routeId, reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestingRefreshToken(true));
        dispatch(setRequestingDownloadDetails(false));
      }
    };

    try {
      await downloadReadableStreamFile(
        'GET',
        DOWNLOAD_ROUTE_DETAILS_URL(routeId),
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingDownloadDetails(false));
      toast.error(error.message);
    }
  };

// Resume Route api
export const resumeRoute =
  (
    payload: {
      vectors: GenerateRoutePayload[];
      towers: { coordinates: number[]; type_id: number | null; deviation_angle: number }[];
    },
    projectId: number,
    routeId: string,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingResumeRoute(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(getRouteLists(projectId, token));
        dispatch(setRequestingResumeRoute(false));
        toast.success(response.message);
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(resumeRoute(payload, projectId, routeId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingResumeRoute(false));
        }
      };

      apiCall('POST', RESTART_ROUTE_URL(routeId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingResumeRoute(false));
      toast.error(error.message);
    }
  };
