import React from 'react';
import { Typography } from 'antd';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import { AOIDetail } from 'store/aoi/types';
import { getAOIArea } from 'utils/util';

interface ApprovedAOiPopupPropType {
  isShowHideApprovedAOI: boolean;
  approvedAOI: AOIDetail;
  handleShowHideApprovedAOI: (isShowHide: boolean) => void;
}

const { Text } = Typography;

const ApprovedAOiPopup = ({
  approvedAOI,
  isShowHideApprovedAOI,
  handleShowHideApprovedAOI
}: ApprovedAOiPopupPropType) => {
  return (
    <div className="approved-aoi-route">
      <div>
        <div className="box-color" style={{ backgroundColor: approvedAOI.color }} />
        <div>
          <Text>Approved AOI</Text>
          <Text strong>{getAOIArea(approvedAOI.geometry)} sq km</Text>
        </div>
      </div>
      <div>
        {isShowHideApprovedAOI ? (
          <EyeOpenIcon onClick={() => handleShowHideApprovedAOI(false)} />
        ) : (
          <EyeCloseIcon onClick={() => handleShowHideApprovedAOI(true)} />
        )}
      </div>
    </div>
  );
};

export default ApprovedAOiPopup;
