import { lightDarkGrey2 } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const EyeCloseIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8315 2.83146C13.0145 2.6484 13.0145 2.3516 12.8315 2.16854C12.6484 1.98549 12.3516 1.98549 12.1685 2.16854L2.16854 12.1685C1.98549 12.3516 1.98549 12.6484 2.16854 12.8315C2.3516 13.0145 2.6484 13.0145 2.83146 12.8315L4.72834 10.9346C5.55198 11.3145 6.49491 11.5625 7.5 11.5625C9.13611 11.5625 10.6075 10.9054 11.6677 10.0917C12.1986 9.68426 12.6341 9.23179 12.9397 8.79145C13.2395 8.35949 13.4375 7.90529 13.4375 7.5C13.4375 7.09471 13.2395 6.64051 12.9397 6.20855C12.6341 5.76821 12.1986 5.31574 11.6677 4.90835C11.4986 4.77855 11.319 4.65273 11.13 4.53293L12.8315 2.83146ZM9.45544 6.20747L8.7692 6.89371C8.85705 7.07729 8.90625 7.2829 8.90625 7.5C8.90625 8.27665 8.27665 8.90625 7.5 8.90625C7.2829 8.90625 7.07729 8.85705 6.89371 8.7692L6.20747 9.45544C6.57799 9.70084 7.02231 9.84375 7.5 9.84375C8.79442 9.84375 9.84375 8.79442 9.84375 7.5C9.84375 7.02231 9.70084 6.57799 9.45544 6.20747Z"
        fill={color}
      />
      <path
        d="M7.86772 5.18493C7.95181 5.19818 8.03836 5.17373 8.09856 5.11353L9.17206 4.04003C9.30441 3.90768 9.24933 3.68311 9.06794 3.63695C8.56953 3.51011 8.04386 3.4375 7.5 3.4375C5.86389 3.4375 4.39251 4.09462 3.33226 4.90835C2.80145 5.31574 2.3659 5.76821 2.06031 6.20855C1.76054 6.64051 1.5625 7.09471 1.5625 7.5C1.5625 7.90529 1.76054 8.35949 2.06031 8.79145C2.308 9.14837 2.64108 9.51325 3.04106 9.85564C3.13791 9.93854 3.2818 9.93029 3.37195 9.84014L5.11353 8.09856C5.17373 8.03836 5.19818 7.95181 5.18493 7.86772C5.16605 7.74792 5.15625 7.6251 5.15625 7.5C5.15625 6.20558 6.20558 5.15625 7.5 5.15625C7.6251 5.15625 7.74792 5.16605 7.86772 5.18493Z"
        fill={color}
      />
    </svg>
  );
};
EyeCloseIcon.defaultProps = {
  color: lightDarkGrey2,
  height: 18,
  width: 25,
  onClick: () => {}
};

export default EyeCloseIcon;
