import { primary } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const EyeOpenIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 6.09375C6.72335 6.09375 6.09375 6.72335 6.09375 7.5C6.09375 8.27665 6.72335 8.90625 7.5 8.90625C8.27665 8.90625 8.90625 8.27665 8.90625 7.5C8.90625 6.72335 8.27665 6.09375 7.5 6.09375Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3.4375C5.86389 3.4375 4.39251 4.09462 3.33226 4.90835C2.80145 5.31574 2.3659 5.76821 2.06031 6.20855C1.76054 6.64051 1.5625 7.09471 1.5625 7.5C1.5625 7.90529 1.76054 8.35949 2.06031 8.79145C2.3659 9.23179 2.80145 9.68426 3.33226 10.0917C4.39251 10.9054 5.86389 11.5625 7.5 11.5625C9.13611 11.5625 10.6075 10.9054 11.6677 10.0917C12.1986 9.68426 12.6341 9.23179 12.9397 8.79145C13.2395 8.35949 13.4375 7.90529 13.4375 7.5C13.4375 7.09471 13.2395 6.64051 12.9397 6.20855C12.6341 5.76821 12.1986 5.31574 11.6677 4.90835C10.6075 4.09462 9.13611 3.4375 7.5 3.4375ZM5.15625 7.5C5.15625 6.20558 6.20558 5.15625 7.5 5.15625C8.79442 5.15625 9.84375 6.20558 9.84375 7.5C9.84375 8.79442 8.79442 9.84375 7.5 9.84375C6.20558 9.84375 5.15625 8.79442 5.15625 7.5Z"
        fill={color}
      />
    </svg>
  );
};
EyeOpenIcon.defaultProps = {
  color: primary,
  height: 18,
  width: 25,
  onClick: () => {}
};

export default EyeOpenIcon;
