import { IconProps } from 'types/common.types';

const AngleIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7188 14.0625C17.7188 14.2863 17.6299 14.5009 17.4716 14.6591C17.3134 14.8174 17.0988 14.9062 16.875 14.9062H5.0625C4.83872 14.9062 4.62411 14.8174 4.46588 14.6591C4.30764 14.5009 4.21875 14.2863 4.21875 14.0625V5.90625H2.25C2.02622 5.90625 1.81161 5.81736 1.65338 5.65912C1.49514 5.50089 1.40625 5.28628 1.40625 5.0625C1.40625 4.83872 1.49514 4.62411 1.65338 4.46588C1.81161 4.30764 2.02622 4.21875 2.25 4.21875H4.21875V2.25C4.21875 2.02622 4.30764 1.81161 4.46588 1.65338C4.62411 1.49514 4.83872 1.40625 5.0625 1.40625C5.28628 1.40625 5.50089 1.49514 5.65912 1.65338C5.81736 1.81161 5.90625 2.02622 5.90625 2.25V13.2188H16.875C17.0988 13.2188 17.3134 13.3076 17.4716 13.4659C17.6299 13.6241 17.7188 13.8387 17.7188 14.0625ZM7.80469 5.92664C9.19601 6.04311 10.5011 6.6487 11.4883 7.63595C12.4756 8.62321 13.0812 9.92829 13.1977 11.3196C13.2153 11.5304 13.3114 11.7268 13.467 11.8701C13.6227 12.0133 13.8264 12.0929 14.0379 12.093H14.1082C14.2191 12.0842 14.3272 12.0535 14.4263 12.0027C14.5253 11.9518 14.6133 11.8819 14.6852 11.797C14.7571 11.712 14.8114 11.6137 14.8451 11.5076C14.8789 11.4016 14.8913 11.2899 14.8816 11.179C14.7319 9.38968 13.953 7.71131 12.6831 6.44185C11.4133 5.17239 9.73467 4.39396 7.94531 4.24477C7.83488 4.23553 7.72371 4.24814 7.61815 4.28187C7.51259 4.3156 7.4147 4.36979 7.33009 4.44135C7.24547 4.51291 7.17577 4.60044 7.12498 4.69893C7.07419 4.79742 7.0433 4.90496 7.03406 5.01539C7.02483 5.12582 7.03744 5.237 7.07117 5.34256C7.1049 5.44812 7.15909 5.546 7.23065 5.63062C7.30221 5.71523 7.38973 5.78493 7.48823 5.83572C7.58672 5.88651 7.69425 5.91741 7.80469 5.92664Z"
        fill={color}
      />
    </svg>
  );
};
AngleIcon.defaultProps = {
  color: '#95969D',
  height: 18,
  width: 18,
  onClick: () => {}
};

export default AngleIcon;
