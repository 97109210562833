import { Button, Select as AntdSelect, Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';
import LocationPinIcon from 'assets/svg/LocationPinIcon';
import TowerIcon from 'assets/svg/TowerIcon';
import { useAppSelector } from 'hooks/useReduxHook';
import { find } from 'lodash';
import { getTowerTypeOptions } from 'store/route';
import { TowerType } from 'store/route/types';

const { Text } = Typography;

interface AddnewGntPointPropstypes {
  towers: TowerType[] | [];
  newTowers: TowerType[] | [];
  gntTowerData: {
    coordinates: number[];
    type: string;
    name: string;
    deviation_angle: number;
  } | null;
  handleClosePopUp: () => void;
  handleDrawmode: () => void;
  handleDrawmodeEnd: () => void;
  handleShowResumeRoute: () => void;
}

const AddnewGntPoint = ({
  gntTowerData,
  towers,
  newTowers,
  handleClosePopUp,
  handleShowResumeRoute,
  handleDrawmode,
  handleDrawmodeEnd
}: AddnewGntPointPropstypes) => {
  const towerOptions = useAppSelector((state) => getTowerTypeOptions(state));
  const gntOldTower = towers[towers.length - 1] || null;
  const gntNewTower = newTowers[newTowers.length - 1] || null;

  const isSameGntTower =
    gntOldTower?.geometry?.coordinates[0] === gntNewTower?.geometry?.coordinates[0] &&
    gntOldTower?.geometry?.coordinates[1] === gntNewTower?.geometry?.coordinates[1];

  return (
    <div className="line-new-point-popup line-gnt-point-popup">
      {gntTowerData && (
        <div className="modal-title-new-point">
          <div>
            <LocationPinIcon />
            <Text className="tower-name">{gntTowerData.name}</Text>
            <Text className="tower-coord">
              {`(${[
                gntTowerData.coordinates[1].toFixed(4),
                gntTowerData.coordinates[0].toFixed(4)
              ].toString()})`}
            </Text>
          </div>
          <CloseIcon onClick={handleClosePopUp} />
        </div>
      )}
      <div>
        <div>
          <TowerIcon />
          <AntdSelect
            showArrow
            className="tower-type"
            onChange={() => {}}
            aria-readonly
            style={{ width: 75 }}
            value={find(towerOptions, ['label', 'Gantry'])}
            options={towerOptions}
            popupClassName="tower-type-select-popup"
            placeholder="Select Type"
          />
        </div>
      </div>
      <div className="gnt-confirm-add-end-btn">
        {!isSameGntTower && (
          <Button onClick={handleShowResumeRoute} type="primary">
            Resume
          </Button>
        )}
        <Button onClick={handleDrawmode} className="add-new-tower">
          Add New Tower
        </Button>
        {!isSameGntTower && (
          <Button onClick={handleDrawmodeEnd} type="link">
            finish adding tower
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddnewGntPoint;
