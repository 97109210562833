import { IconProps } from 'types/common.types';

const LocationPinIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8.24963C8.37975 8.24963 7.875 7.74487 7.875 7.12463C7.875 6.50437 8.37975 5.99962 9 5.99962C9.62025 5.99962 10.125 6.50437 10.125 7.12463C10.125 7.74487 9.62025 8.24963 9 8.24963ZM9 4.49963C7.5525 4.49963 6.375 5.67713 6.375 7.12463C6.375 8.57212 7.5525 9.74963 9 9.74963C10.4475 9.74963 11.625 8.57212 11.625 7.12463C11.625 5.67713 10.4475 4.49963 9 4.49963ZM9 14.7345C7.74375 13.5465 4.5 10.2113 4.5 7.4415C4.5 4.99275 6.51825 3 9 3C11.4818 3 13.5 4.99275 13.5 7.4415C13.5 10.2113 10.2562 13.5465 9 14.7345ZM9 1.5C5.69175 1.5 3 4.16475 3 7.4415C3 11.5477 8.28675 16.1257 8.51175 16.3185C8.65275 16.4392 8.826 16.5 9 16.5C9.174 16.5 9.34725 16.4392 9.48825 16.3185C9.71325 16.1257 15 11.5477 15 7.4415C15 4.16475 12.3082 1.5 9 1.5Z"
        fill={color}
      />
    </svg>
  );
};
LocationPinIcon.defaultProps = {
  color: '#95969D',
  height: 18,
  width: 18,
  onClick: () => {}
};

export default LocationPinIcon;
