import { IconProps } from 'types/common.types';

const TowerIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12429_20738)">
        <path
          d="M15.1916 17.2495H13.2253L11.5291 10.1197H14.4448C14.6512 10.1197 14.8201 9.95079 14.8201 9.74439C14.8201 9.62056 14.7563 9.50422 14.6512 9.43294L12.3997 7.93191C12.3397 7.89065 12.2646 7.86813 12.1933 7.86813H11.4428V4.86611H14.0733C14.2797 4.86611 14.4486 4.69725 14.4486 4.49084C14.4486 4.36701 14.3848 4.24694 14.2797 4.1794L12.0282 2.67837C11.9682 2.6371 11.8931 2.61458 11.8181 2.61458H10.8987L9.32261 0.160421C9.18375 -0.0534737 8.82727 -0.0534737 8.69218 0.160421L7.10486 2.61458H6.18549C6.11045 2.61458 6.03915 2.6371 5.97909 2.67837L3.72757 4.1794C3.55494 4.29574 3.50991 4.52838 3.62248 4.70101C3.69379 4.80609 3.8101 4.86987 3.93393 4.86987H6.56072V7.8719H5.81023C5.73519 7.8719 5.66388 7.89441 5.6001 7.93568L3.34857 9.43671C3.17594 9.55305 3.13091 9.78569 3.24349 9.95832C3.3148 10.0634 3.4311 10.1272 3.55494 10.1272H6.44815L4.75201 17.257H2.81946C2.63185 17.257 2.46298 17.3921 2.4367 17.576C2.40293 17.8011 2.58305 18 2.80821 18H15.1803C15.368 18 15.5368 17.8649 15.5631 17.681C15.5969 17.4484 15.4167 17.2495 15.1916 17.2495ZM12.152 15.9624L9.47272 12.4462L10.8762 10.6075L12.152 15.9624ZM10.0919 7.86813H7.89665L8.99614 6.86997L10.0919 7.86813ZM9.55525 6.35588L10.6885 5.33144V7.38782L9.55525 6.35588ZM9.98304 9.36916H7.99044L8.61338 8.61866H9.38266L9.98304 9.36916ZM7.31126 7.38033V5.3352L8.43702 6.35965L7.31126 7.38033ZM10.302 10.1197L8.9999 11.8346L7.69025 10.1197H10.302ZM12.077 8.61866L13.2027 9.36916H10.9437L10.3433 8.61866H12.077ZM11.7017 3.36508L12.8275 4.11558H10.8462L10.0957 3.36508H11.7017ZM8.9999 1.05727L9.99807 2.61458H7.99797L8.9999 1.05727ZM9.78417 4.11561H8.21936L8.96986 3.36512H9.03364L9.78417 4.11561ZM10.0919 4.86611L8.99614 5.85678L7.90791 4.86611H10.0919ZM5.17231 4.11561L6.29807 3.36512H7.90415L7.15365 4.11561H5.17231ZM4.79704 9.36916L5.9228 8.61866H7.63773L7.01479 9.36916H4.79704ZM7.10862 10.585L8.52708 12.4463L5.81772 15.9999L7.10862 10.585ZM5.81772 17.2495L8.99987 13.0729L12.182 17.2495H5.81772Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_12429_20738">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
TowerIcon.defaultProps = {
  color: '#95969D',
  height: 18,
  width: 18,
  onClick: () => {}
};

export default TowerIcon;
