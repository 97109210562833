import { createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { defaultGrey, lightred1 } from 'constants/theme.constants';
import { aoiRouteColors } from 'constants/common.constant';
import { AOIStausType } from 'store/aoi/types';
import { RouteDetail, RouteState } from './types';

export const initialState: RouteState = {
  isRequestingGenerateRoute: false,
  isRequestingEditRoute: false,
  isRequestingRouteFiles: false,
  isRequestingUpdateRouteDetails: false,
  routeList: [],
  isRequestingTowerType: false,
  isRequestingDeviationAngle: false,
  isRequestingUpdateTowerType: false,
  towerTypes: [],
  lineRouteCoords: [],
  lineRouteLonLatCoords: [],
  lineRouteTowerPayload: [],
  lineTowerDetails: [],
  isRequestingDownloadDetails: false,
  isRequestingResumeRoute: false
};

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setRequestingRouteFiles: (state, action) => {
      state.isRequestingRouteFiles = action.payload;
    },
    setRoutes: (state, action) => {
      const data = orderBy(action.payload, ['requested_at'], ['asc']).map(
        (d: RouteDetail, i: number) => ({
          ...d,
          color: d.status === AOIStausType.invalid ? lightred1 : aoiRouteColors[i] || defaultGrey,
          index: i + 1
        })
      );
      // state.routeList = action.payload;
      state.routeList = data;
    },
    setRequestingGenerateRoute: (state, action) => {
      state.isRequestingGenerateRoute = action.payload;
    },
    setRequestingEditRoute: (state, action) => {
      state.isRequestingEditRoute = action.payload;
    },
    setRequestingUpdateRouteDetails: (state, action) => {
      state.isRequestingUpdateRouteDetails = action.payload;
    },
    setRequestingTowerType: (state, action) => {
      state.isRequestingTowerType = action.payload;
    },
    setTowerTypes: (state, action) => {
      state.towerTypes = action.payload;
    },
    setRequestingDeviationAngle: (state, action) => {
      state.isRequestingDeviationAngle = action.payload;
    },
    setRequestingUpdateTowerType: (state, action) => {
      state.isRequestingUpdateTowerType = action.payload;
    },
    setRequestingDownloadDetails: (state, action) => {
      state.isRequestingDownloadDetails = action.payload;
    },
    setLineTowerDetails: (state, action) => {
      state.lineTowerDetails = action.payload;
    },
    setLineRouteCoords: (state, action) => {
      state.lineRouteCoords = action.payload;
    },
    setLineRouteLonLatCoords: (state, action) => {
      state.lineRouteLonLatCoords = action.payload;
    },
    setLineRouteTowerPayload: (state, action) => {
      state.lineRouteTowerPayload = action.payload;
    },
    setRequestingResumeRoute: (state, action) => {
      state.isRequestingResumeRoute = action.payload;
    },
    clearRoute: () => {
      return initialState;
    }
  }
});
