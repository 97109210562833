import React from 'react';
import { find } from 'lodash';
import { Button, Select as AntdSelect, Typography } from 'antd';
import LocationPinIcon from 'assets/svg/LocationPinIcon';
import TowerIcon from 'assets/svg/TowerIcon';
import AngleIcon from 'assets/svg/AngleIcon';
import CloseIcon from 'assets/svg/CloseIcon';
import DeleteIcon from 'assets/svg/DeleteIcon';
import { useAppSelector } from 'hooks/useReduxHook';
import { getTowerTypeOptions } from 'store/route';

const { Text } = Typography;

interface AddNewPointPopUpPropstypes {
  towerData: {
    type: string;
    towerName: string;
    deviation_angle: number;
  } | null;
  towerCoord: string | null;
  isDeleteTower: boolean;
  handleClosePopUp: () => void;
  handleConfirmEditRoute: () => void;
  handleDeletePoint: () => void;
}

const AddNewPointPopUp = ({
  towerData,
  towerCoord,
  isDeleteTower,
  handleClosePopUp,
  handleConfirmEditRoute,
  handleDeletePoint
}: AddNewPointPopUpPropstypes) => {
  const towerOptions = useAppSelector((state) => getTowerTypeOptions(state));

  return (
    <div className="line-new-point-popup">
      <div className="modal-title-new-point">
        <div>
          <LocationPinIcon />
          <Text className="tower-name">{towerData ? towerData.towerName : ''}</Text>
          <Text className="tower-coord">{towerCoord ? `(${towerCoord})` : ''}</Text>
        </div>
        <CloseIcon onClick={handleClosePopUp} />
      </div>
      <div>
        <div>
          <TowerIcon />
          <AntdSelect
            showArrow
            className="tower-type"
            onChange={() => {}}
            aria-readonly
            value={towerData ? find(towerOptions, ['label', towerData.type]) : undefined}
            options={towerOptions}
            popupClassName="tower-type-select-popup"
            placeholder="Select Type"
          />
        </div>
        <div>
          <AngleIcon />
          <Text>{towerData ? `${towerData.deviation_angle.toFixed(0)}°` : ''} </Text>
        </div>
      </div>
      <div className={isDeleteTower ? 'delete-icon-div' : ''}>
        {isDeleteTower && (
          <Button
            className="delete-btn"
            onClick={handleDeletePoint}
            type="text"
            icon={<DeleteIcon color="#696B72" />}
          />
        )}
        <Button type="link" style={{ opacity: 0 }} onClick={handleConfirmEditRoute}>
          confirm
        </Button>
      </div>
    </div>
  );
};

export default AddNewPointPopUp;
