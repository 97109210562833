import { useEffect, useRef } from 'react';
import { orderBy } from 'lodash';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { LineString } from 'ol/geom';
import { RouteDetail, TowerType } from 'store/route/types';
import { useMap } from './MapContext';

interface RouteLineComponentProps {
  editedRouteData: RouteDetail | null;
}

const RouteLineComponent = ({ editedRouteData }: RouteLineComponentProps) => {
  const map = useMap();
  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer>();
  const vectorSourceRef = useRef<VectorSource>();

  useEffect(() => {
    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      zIndex: 100,
      style: (feature) => {
        const routeColor = feature.get('color');
        return new Style({
          stroke: new Stroke({
            color: routeColor,
            width: 2
          }),
          zIndex: 100
        });
      }
    });
    vectorLayerRef.current.set('name', 'routelist');
    if (map) {
      map.addLayer(vectorLayerRef.current);
    }
    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
      }
    };
  }, []);
  useEffect(() => {
    if (editedRouteData) {
      const oldRouteLineCoords = orderBy(editedRouteData.towers, 'id').map((t: TowerType) =>
        fromLonLat(t.geometry.coordinates)
      );

      const oldRouteLineFeature = new Feature({
        geometry: new LineString(oldRouteLineCoords),
        fillColor: editedRouteData.color,
        type: 'LineString'
      });
      oldRouteLineFeature.setId('old-line-feature');
      oldRouteLineFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color: editedRouteData.color,
            width: 2,
            lineDash: [5, 5]
          }),
          zIndex: 70
        })
      );
      vectorSourceRef.current?.addFeature(oldRouteLineFeature);
    }
    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
      }
    };
  }, [editedRouteData]);

  return null;
};

export default RouteLineComponent;
